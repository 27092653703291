<template>
  <div>
  </div>
</template>

<script>
let FileSaver = require('../utils/FileSaver');
export default {
    name: 'svg-renderer',
    data() {
        return {
            document: null,
            svg: null,
            path: null,
            bezier: '',
            bezierStep: 32,
            res: 640,
            segments: '',
            r: 0,
            w: 0
        }
    },
    methods: {
        setProps(document, r, w) {
            this.document = document;
            this.r = r,
            this.w = w
        },
        init(svg, drawSegments, drawBezier, bezierStep) {
            this.svg = svg;
            this.path = null;
            svg.innerHTML = '';

            this.segments = '';
            if (drawSegments) {
            svg.innerHTML += '<path id="segments" stroke="#000" stroke-linecap="round" stroke-linejoin="round" fill="none" d=""></path>';
            this.segments = this.svg.querySelector('#segments');
            }

            this.bezier = '';
            if (drawBezier) {
                svg.innerHTML += '<path id="bezier" stroke="#000" stroke-linecap="round" stroke-linejoin="round" fill="none" d=""></path>';
                this.bezier = this.svg.querySelector('#bezier');
            }

            this.bezierStep = bezierStep || 1;

            this.clear();
        },
        saveSvg(xs, ys, permaLink) {
            let res = this.res;
            let ns = 'http://www.w3.org/2000/svg';
            let svg = this.document.createElementNS(ns, 'svg');
            svg.setAttribute('xmlns', ns);
            svg.setAttribute('width', res);
            svg.setAttribute('height', res);
            svg.setAttribute('version', '1.1');
            let bezier = this.bezier;
            this.init(svg, !bezier, bezier, this.bezierStep);
            this.draw(xs, ys);
            if (permaLink == undefined) {
                this.save();
            } else {
                this.saveUsingPermaLink(permaLink);   
            }
        },
        clear() {
            let svg = this.svg;
            if (this.segments) {
                this.segments.setAttribute('d', '');
                this.segments.setAttribute('stroke-width', this.w);
            }
            if (this.bezier) {
                this.bezier.setAttribute('d', '');
                this.bezier.setAttribute('stroke-width', this.w);
            }

            if (this.r) {
                let width = svg.width.baseVal.value;
                let height = svg.height.baseVal.value;
                let scale = Math.min(width * 0.9 / 2.0 / this.r, height * 0.9 / 2.0 / this.r);
                let m = svg.createSVGMatrix().translate(width / 2.0, height / 2.0).scale(scale);
                let transform = svg.createSVGTransformFromMatrix(m);
                if (this.segments) {
                    this.segments.transform.baseVal.initialize(transform);
                }
                if (this.bezier) {
                    this.bezier.transform.baseVal.initialize(transform);
                }
            }
        } ,
        draw(xs, ys) {
            if (this.segments) {
                this.drawSegments(this.segments, xs, ys);
            }
            if (this.bezier) {
                this.drawBezier(this.bezier, xs, ys);
            }
        },
        round(x) {
            return Math.round(x * 1000) / 1000;
        },
        drawSegments(path, xs, ys) {
            const round = this.round
            let data = [path.getAttribute('d'), 'M'];
            data.push(round(xs[0]));
            data.push(round(ys[0]));
            const n = xs.length;
            if (n > 1) {
                data.push('L');
            }
            for (let i = 1; i < n; i++) {
                data.push(round(xs[i]));
                data.push(round(ys[i]));
            }
            path.setAttribute('d', data.join(' '));
        },
        drawBezier(path, xs, ys) {
            const round = this.round;
            const n = xs.length;
            const step = Math.min(this.bezierStep, n - 1);
            const factor = 0.5 * step / 3;
            let rxs = [];
            let rys = [];
            let cxs = [];
            let cys = [];
            for (let i = 0; i < n; i += step) {
                let prev = Math.max(0, i - 1);
                let next = Math.min(n - 1, i + 1);
                rxs.push(xs[i]);
                rys.push(ys[i]);
                cxs.push(factor * (xs[next] - xs[prev]));
                cys.push(factor * (ys[next] - ys[prev]));
            }
            let rn = rxs.length;

            let data = [
                path.getAttribute('d'),
                'M',
                round(rxs[0]), round(rys[0]),
                'C',
                round(rxs[0] + cxs[0]), round(rys[0] + cys[0]) + ',',
                round(rxs[1] - cxs[1]), round(rys[1] - cys[1]) + ',',
                round(rxs[1]), round(rys[1])
                ];
                if (rn > 2) {
                        data.push('S');
                        for (let i = 2; i < rn; i++) {
                        data.push(round(rxs[i] - cxs[i]));
                        data.push(round(rys[i] - cys[i]) + ',');
                        data.push(round(rxs[i]));
                        data.push(round(rys[i]));
                    }
                }
            path.setAttribute('d', data.join(' '));
        },
        save() {
            let blob = new Blob([this.svg.outerHTML], {type: 'application/svg+xml'});
            FileSaver.saveAs(blob, 'harmonograph.svg');
        },
        saveUsingPermaLink(permalink) {
            let blob = new Blob([this.svg.outerHTML], {type: 'application/svg+xml'});
            FileSaver.saveAs(blob, 'harmonograph_' + permalink + '.svg');
        }
    }
}
</script>

<style>

</style>