<template>
    <div id="page">
        <svg-renderer id="svgPane" ref="svgRenderer" />
        <harmonogram-panel id="overview-panel" ref="harmonogramPanel"/>
        <canvas-renderer id="output" ref="outputCanvas" />
        <div id="middle" >
            <input-pane ref="inputPane" id="inputPane" v-on:input-change="inputChange" v-on:speed-change="speedChange"
                />
            <button-pane id="buttonPane" ref="buttonPane" v-on:start="start" v-on:stop="stop"
                v-on:reset="reset" v-on:restore-defaults="restoreDefaults"
                v-on:save="doSave()"
                v-on:saveUsingPermalink="doSaveUsingPermalink()"
                v-on:create-permalink="generatePermalink"/>
        </div>

    </div>
</template>

<script>
import ButtonPane from './ButtonPane.vue';
import CanvasRenderer from './CanvasRenderer.vue'
import HarmonogramPanel from './HarmonogramPanel.vue';
import SvgRenderer from './SvgRenderer.vue';
import InputPane from './InputPane.vue';
export default {
  components: { CanvasRenderer, ButtonPane, SvgRenderer, HarmonogramPanel, InputPane },
    name: 'harmonogram-screen',
    data() {
        return {
            d: 900,
            c: 800,
            p: 900,
            q: 700,
            r: 500,
            A: this.toRadians(10),
            B: this.toRadians(10),
            u: 0,
            v: 0,
            R: 0.001,
            S: 0.001, 
            f: 0.3,
            g: 0.302,
            h: 0.0008,
            w: 0.2,
            s: 40,
            t: 0.0,
            dt: 0.01,
            x: null,
            y: null,
            xs: [],
            ys: [],
            alpha: null,
            beta: null,
            gamma: null,
            output: null,
            overview: null,
            startStop:null,
            permalink: null,
            intervalId: null
        }
    },
    methods: {
        setOutputCanvasAndContext(canvas, context) {
            const outputCanvas = this.$refs.outputCanvas;
            outputCanvas.setCanvasAndContext(canvas, context, true);
        },
        clear() {
            this.t = 0.0;
            this.updateXY();
            this.xs = [this.x];
            this.ys = [this.y];

            const output = this.$refs.outputCanvas;
            output.clear(this.w, this.r);
            // drawOverview(true);
            this.drawOverview(true, this.d, this.c, this.r, 
                this.alpha, this.beta, this.gamma, this.p, this.q) 
        },
        updateXY() {
            this.alpha = this.A * Math.sin(2.0 * Math.PI * (this.f * this.t + this.u)) * Math.exp(-this.R * this.t);
            this.beta  = this.B * Math.sin(2.0 * Math.PI * (this.g * this.t + this.v)) * Math.exp(-this.S * this.t);
            this.gamma = 2.0 * Math.PI * this.h * this.t;

            let xa = this.p * Math.cos(this.alpha) + this.q * Math.sin(this.alpha) - this.d;
            let ya = this.q * Math.cos(this.alpha) - this.p * Math.sin(this.alpha);
            let xb = xa * Math.cos(this.beta) - ya * Math.sin(this.beta);
            let yb = ya * Math.cos(this.beta) + xa * Math.sin(this.beta) - this.c;
            this.x = xb * Math.cos(this.gamma) - yb * Math.sin(this.gamma);
            this.y = yb * Math.cos(this.gamma) + xb * Math.sin(this.gamma);
        },
        start() {
            if (this.intervalId == null) {
                this.setInputDisabled(true);
                this.intervalId = setInterval(function() {
                    this.step();
                }.bind(this), 1000 * this.dt);
            }
        },
        stop() {
            if (this.intervalId != null) {
                clearInterval(this.intervalId);
                this.intervalId = null;
                this.setInputDisabled(false);
            }
        },
        step() {
            let newXs = [this.xs[this.xs.length - 1]];
            let newYs = [this.ys[this.ys.length - 1]];
            for (let i = 0; i < this.s; ++i) {
                this.t += this.dt;
                this.updateXY();
                this.xs.push(this.x);
                this.ys.push(this.y);
                newXs.push(this.x);
                newYs.push(this.y);
            }

            const output = this.$refs.outputCanvas;
            output.draw(newXs, newYs);
            
            this.drawOverview(false, this.d, this.c, this.r, 
                this.alpha, this.beta, this.gamma, this.p, this.q) 
        },
        reset() {
            this.stop();
            this.clear();
        },
        toRadians(degrees) {
            return degrees / 180.0 * Math.PI;
        },
        setSvgInfo(document) {
            let renderer = this.$refs.svgRenderer;
            renderer.setProps(document, this.r, this.w);
        },
        doSave() {
            let renderer = this.$refs.svgRenderer;
            renderer.saveSvg(this.xs, this.ys);
        },
        setOverviewInfo(canvas, context) {
            let harmonogram = this.$refs.harmonogramPanel;
            harmonogram.setCanvasInfo(canvas, context);
        },
        drawOverview(variables, d, c, r, alpha, beta, gamma, p, q) {
            let harmonogram = this.$refs.harmonogramPanel;
            harmonogram.drawOverview(variables, d, c, r, alpha, beta, gamma, p, q);
        },
        inputChange() {
            console.log('inputChange')
            this.loadFromInputData();
            this.clear();
        },
        speedChange() {
            console.log('speedChange')
            this.loadFromInputData();
        },
        setInputDisabled(isDisabled) {
            let inputPanel = this.$refs.inputPane;
            inputPanel.setInputDisabled(isDisabled);

            let buttonPanel = this.$refs.buttonPane;
            buttonPanel.disableRestore(isDisabled);
        },
        loadFromInputData() {
            const inputData = this.$store.state.inputData;
            this.d = inputData.d;
            this.c = inputData.c;
            this.p= inputData.p;
            this.q = inputData.q;
            this.r = inputData.r;
            this.A = this.toRadians(inputData.A);
            this.B = this.toRadians(inputData.B);
            this.u = inputData.u;
            this.v = inputData.v;
            this.R = inputData.R;
            this.S = inputData.S;
            this.f = inputData.f;
            this.g = inputData.g;
            this.h = inputData.h;
            this.w = inputData.w;
            this.s= inputData.s;
        },
        restoreDefaults() {
            let inputPanel = this.$refs.inputPane;
            inputPanel.restoreDefaults();
        },
        generatePermalink() {
            let inputPanel = this.$refs.inputPane;
            const permalink = inputPanel.getPermalink();

            let buttonPanel = this.$refs.buttonPane;
            buttonPanel.setPermalink(permalink);
        },
        fromPermalink() {
            let link = document.location.hash;
            let values = link.substring(1).split('&');
            
            let inputPanel = this.$refs.inputPane;
            inputPanel.loadFromLink(values)
        },
        doSaveUsingPermalink() {
            let inputPanel = this.$refs.inputPane;
            const permalink = inputPanel.getPermalink();

            let renderer = this.$refs.svgRenderer;
            renderer.saveSvg(this.xs, this.ys, permalink);
        },
        initInputPane() {
            this.fromPermalink();
        }
    }
}
</script>

<style>
    #page {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;
    grid-template-areas: 
      "harmonogram middle output";
    justify-items: start;
  }
  #overview-panel {
    grid-area: harmonogram;
    margin-left: 20px;
  }

  #svgPane {
    grid-area: svg;
    display: none;
  }

  #output {
    grid-area: output;
    justify-self: end;
  }

  #buttonPane {
    grid-area: buttons;
  }

  #inputPane {
    grid-area: inputpane;
    padding: 30px 20px;
  }

  #middle {
    grid-area: middle;
    padding: 0 20px;
  }
</style>