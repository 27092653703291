<template>
  <div>
      <div id="container">
            <div id="startstop-section">
                <button id="startStop" v-on:click="startStop">{{ running ? 'Stop' : 'Start' }}</button>
            </div>

            <div id = "bottom">
                <div id="reset-section">
                    <button id="reset" v-on:click="reset">Reset</button>
                </div>


                <div id="save-section">
                    <button id="save" v-on:click="save">Save</button>
                    <button id="save" v-on:click="saveUsingPermalink">Save (Permalink)</button>
                </div>

                <div id="defaults">
                    <button id="defaults" v-on:click="restoreDefaults" v-bind:disabled="restoreDisabled">Restore All</button>
                </div>
            </div>
            <div id="permalink-section">
                <a id="permalink" href="#" v-on:click="createPermalinkClicked">Link to this creation</a>
            </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'button-pane',
    data() {
        return {
            running: false,
            restoreDisabled: false
        }
    },
    methods: {
        startStop() {
            this.running = !this.running;
            if (this.running) {
                this.$emit('start');
            } else {
                this.$emit('stop');
            }
        },
        reset() {
            this.running = false;
            this.$emit('reset');
        },
        save() {
            this.$emit('save');
        },
        saveUsingPermalink() {
            this.$emit('saveUsingPermalink');
        },
        restoreDefaults() {
            this.$emit('restore-defaults');
        },
        disableRestore(isDisabled) {
            this.restoreDisabled = isDisabled;
        },
        setPermalink(link) {
            console.log('setting link: ' + link);
            let aTag = document.getElementById('permalink');
            aTag.href=link;
        },
        createPermalinkClicked() {
            this.$emit('create-permalink');
        }
    }

}
</script>

<style>

button#startStop {
	width: 6em;
	height: 3em;
	font-size: 2em;
    margin-bottom: 10px;
}

/* button#reset,  button#save{
	padding: 30px;
} */

#container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "startstop startstop"
        "bottom bottom"
        "permalink permalink";
}

#top {
    grid-area: top;
}

#bottom {
    grid-area: bottom;
    display: flex;
    justify-content: space-around;
    height: auto;
}

#bottom button {
    margin: 5px;
}

#save-section {
    grid-area: save;
}

#reset-section {
    grid-area: reset;
}

#startstop-section {
    grid-area: startstop;
}

#permalink-section {
    grid-area: permalink;
    align-self: stretch;
    text-align: center;
    width: 100%;
}

</style>