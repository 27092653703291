<template>
    <canvas id="overview-panel" class="output overview" width="320" height="480">
    </canvas>
</template>

<script>
export default {
    name: 'harmonogram-panel',
    data() {
        return {
            overview: null,
            overc: null
        }
    },
    methods: {
        setCanvasInfo(canvas, context) {
            this.overview = canvas;
            this.overc = context;
        },
        drawOverview(variables, d, c, r, alpha, beta, gamma, p, q) {
            let overview = this.overview;
            let overc = this.overc;
            overc.setTransform(1, 0, 0, 1, 0, 0);
            overc.clearRect(0, 0, overview.width, overview.height);

            var scale = overview.width * 0.6 / d;
            overc.setTransform(scale, 0, 0, scale, overview.width * 0.2, overview.width * 0.2);

            overc.lineWidth = 5;
            overc.strokeStyle = '#000';
            overc.fillStyle = '#000';
            overc.font = 'italic 80px sans-serif';
            overc.textBaseline = 'bottom';

            // top bar
            overc.beginPath();
            overc.moveTo(0, 0);
            overc.lineTo(d, 0);
            overc.stroke();
            if (variables) {
                overc.textAlign = 'center';
                overc.fillText('d', 0.5 * d, 0);
            }

            // right pendulum
            overc.save();
            {
                overc.translate(d, 0);
                overc.rotate(-beta);

                overc.beginPath();
                overc.moveTo(0, 0);
                overc.lineTo(0, 1.8 * c);
                overc.stroke();

                overc.beginPath();
                overc.arc(0, 2.0 * c, 0.2 * c, 0, 2.0 * Math.PI, true);
                overc.stroke();

                // paper
                overc.save();
                {
                    overc.translate(0, c);
                    overc.rotate(-gamma);

                    overc.fillStyle = '#fff';
                    overc.beginPath();
                    overc.arc(0, 0, r, 0, 2.0 * Math.PI, false);
                    overc.fill();

                    overc.beginPath();
                    overc.arc(0, 0, r, 0, 2.0 * Math.PI, false);
                    overc.stroke();

                    overc.beginPath();
                    overc.moveTo(0.8 * r, 0);
                    overc.lineTo(-0.8 * r, 0);
                    overc.moveTo(0, 0.8 * r);
                    overc.lineTo(0, -0.8 * r);
                    overc.moveTo(-0.1 * r, -0.6 * r);
                    overc.lineTo(0, -0.8 * r);
                    overc.lineTo(0.1 * r, -0.6 * r);
                    overc.stroke();

                    if (variables) {
                        overc.textAlign = 'center';
                        overc.fillStyle = '#000';
                        overc.fillText('r', 0.5 * r, 0);
                    }
                }
                overc.restore();

                if (variables) {
                    overc.textAlign = 'left';
                    overc.fillText(' c', 0, 0.5 * c);
                }
            }
            overc.restore();

            // left pendulum
            overc.save();
            {
            overc.rotate(-alpha);

            overc.beginPath();
            overc.moveTo(0, 0);
            overc.lineTo(0, 1.8 * c);
            overc.moveTo(0, q);
            overc.lineTo(p, q);
            overc.stroke();

            overc.beginPath();
            overc.arc(0, 2.0 * c, 0.2 * c, 0, 2.0 * Math.PI, false);
            overc.stroke();

            overc.beginPath();
            overc.arc(p, q, 10.0, 0, 2.0 * Math.PI, false);
            overc.fill();

            if (variables) {
            overc.textAlign = 'center';
            overc.fillText('p', 0.5 * p, q);
            overc.textAlign = 'left';
            overc.fillText(' q', 0, 0.5 * q);
            }
            }
            overc.restore();
            }
            }
}
</script>

<style>

</style>