<template>
  <div id="app">
    <harmongram-screen ref="harmongramScreen" />
  </div>
</template>

<script>
import HarmongramScreen from './components/HarmongramScreen.vue';

export default {
  name: 'App',
  components: {
    HarmongramScreen
  },
  mounted() {
    let output = document.getElementById('output');
    let outputCtx = output.getContext('2d');
    let overview = document.getElementById('overview-panel');
    let overc = overview.getContext('2d');
    const harmongramScreen = this.$refs.harmongramScreen;
    harmongramScreen.setOutputCanvasAndContext(output, outputCtx, true);
    
    

    harmongramScreen.setSvgInfo(document)


    harmongramScreen.setOverviewInfo(overview, overc);
    
    harmongramScreen.clear();

    harmongramScreen.initInputPane();

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
