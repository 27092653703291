<template>
  <div> 
      <table id="input" class="input">
            <tr>
                <td class="desc">Distance between pendulums</td>
                <td class="var"><em>d</em> =</td>
                <td class="input"><input type="number" id="d" v-model.number="dValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> mm</td>
                <td class="input-item-restore" > <button class="input-item-restore" v-on:click="restoreInputItemDefault('d')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Position of paper centre</td>
                <td class="var"><em>c</em> =</td>
                <td class="input"><input type="number" id="c" v-model.number="cValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> mm</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('c')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Length of pen arm</td>
                <td class="var"><em>p</em> =</td>
                <td class="input"><input type="number" id="p" v-model.number="pValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"> mm</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('p')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Position of pen arm</td>
                <td class="var"><em>q</em> =</td>
                <td class="input"><input type="number" id="q" v-model.number="qValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> mm</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('q')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Radius of paper</td>
                <td class="var"><em>r</em> =</td>
                <td class="input"><input type="number" id="r" min="0" v-model.number="rValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> mm</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('r')" v-bind:disabled="inputDisabled">Restore</button></td>
            <tr>
                <td class="desc">Amplitude of left pendulum</td>
                <td class="var"><em>A</em> = </td>
                <td class="input"><input type="number" id="A" min="0" v-model.number="AValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/>&deg;</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('A')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Amplitude of right pendulum</td>
                <td class="var"><em>B</em> = </td>
                <td class="input"><input type="number" id="B" min="0" v-model.number="BValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/>&deg;</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('B')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Phase of left pendulum</td>
                <td class="var"><em>u</em> = </td>
                <td class="input"><input type="number" id="u" min="0" max="1" v-model.number="uValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> (0&hellip;1)</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('u')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Phase of right pendulum</td>
                <td class="var"><em>v</em> = </td>
                <td class="input"><input type="number" id="v" min="0" max="1" v-model.number="vValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> (0&hellip;1)</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('v')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Damping of left pendulum</td>
                <td class="var"><em>R</em> = </td>
                <td class="input"><input type="number" id="R" min="0"  step=".001" v-model.number="RValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> (0&hellip;1)</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('R')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Damping of right pendulum</td>
                <td class="var"><em>S</em> = </td>
                <td class="input"><input type="number" id="S" min="0"  step=".001" v-model.number="SValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> (0&hellip;1)</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('S')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Frequency of left pendulum</td>
                <td class="var"><em>f</em> = </td>
                <td class="input"><input type="number" id="f" min="0"  step=".001" v-model.number="fValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> Hz</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('f')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Frequency of right pendulum</td>
                <td class="var"><em>g</em> = </td>
                <td class="input"><input type="number" id="g" min="0" step=".001" v-model.number="gValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> Hz</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('g')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Frequency of paper rotation</td>
                <td class="var"><em>h</em> = </td>
                <td class="input"><input type="number" id="h"  step=".0001"  v-model.number="hValue" v-on:change="inputChange"  v-bind:disabled="inputDisabled"/> Hz</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('h')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Pen thickness</td>
                <td class="var"><em>w</em> = </td>
                <td class="input"><input type="number" step=".1"  id="w" v-model.number="wValue" v-on:change="inputChange" v-bind:disabled="inputDisabled"/> mm</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('w')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
            <tr>
                <td class="desc">Time speedup factor:</td>
                <td class="var"><em>s</em> = </td>
                <td class="input"><input type="number" id="s" v-model.number="sValue" v-on:change="changeSpeed"  v-bind:disabled="inputDisabled"/> mm</td>
                <td class="input-item-restore" ><button class="input-item-restore" v-on:click="restoreInputItemDefault('s')" v-bind:disabled="inputDisabled">Restore</button></td>
            </tr>
        </table>
  </div>
</template>

<script>
export default {
    name: 'input-pane',
    data() {
        return {
            dValue: 900,
            cValue: 800,
            pValue: 900,
            qValue: 700,
            rValue: 500,
            AValue: 10,
            BValue: 10,
            uValue: 0,
            vValue: 0,
            RValue: 0.001,
            SValue: 0.001, 
            fValue: 0.3,
            gValue: 0.302,
            hValue: 0.0008,
            wValue: 0.2,
            sValue: 40,
            inputDisabled: false
        }
    },
    methods: {
        getInputData() {
            let inputData = {
                d: this.dValue,
                c: this.cValue,
                p: this.pValue,
                q: this.qValue,
                r: this.rValue,
                A: this.AValue,
                B: this.BValue,
                u: this.uValue,
                v: this.vValue,
                R: this.RValue,
                S: this.SValue, 
                f: this.fValue,
                g: this.gValue,
                h: this.hValue,
                w: this.wValue,
                s: this.sValue
            }
            return inputData;
        },
        inputChange() {
            let inputData = {
                d: this.dValue,
                c: this.cValue,
                p: this.pValue,
                q: this.qValue,
                r: this.rValue,
                A: this.AValue,
                B: this.BValue,
                u: this.uValue,
                v: this.vValue,
                R: this.RValue,
                S: this.SValue, 
                f: this.fValue,
                g: this.gValue,
                h: this.hValue,
                w: this.wValue,
                s: this.sValue
            }
            this.$store.commit('UPDATE_INPUTS', inputData);
            this.$emit('input-change');
        },
        changeSpeed() {
            let inputData = this.getInputData();
            inputData.s = this.sValue;
            this.$store.commit('UPDATE_INPUTS', inputData);
            this.$emit('speed-change');
        },
        setInputDisabled(isDisabled) {
            this.inputDisabled =  isDisabled
        },
        getDefaults() {
            return {
                d: 900,
                c: 800,
                p: 900,
                q: 700,
                r: 500,
                A: 10,
                B: 10,
                u: 0,
                v: 0,
                R: 0.001,
                S: 0.001, 
                f: 0.3,
                g: 0.302,
                h: 0.0008,
                w: 0.2,
                s: 40
            };
        },
        restoreDefaults() {
            const defaults = this.getDefaults();
            this.$store.commit('UPDATE_INPUTS', defaults);
            this.$emit('input-change');
            this.loadFromStore();
        },
        loadFromStore() {
            this.dValue = this.$store.state.inputData.d;
            this.cValue = this.$store.state.inputData.c;
            this.pValue = this.$store.state.inputData.p;
            this.qValue = this.$store.state.inputData.q;
            this.rValue = this.$store.state.inputData.r;
            this.AValue = this.$store.state.inputData.A;
            this.BValue = this.$store.state.inputData.B;
            this.uValue = this.$store.state.inputData.u;
            this.vValue = this.$store.state.inputData.v;
            this.RValue = this.$store.state.inputData.R;
            this.SValue = this.$store.state.inputData.S;
            this.fValue = this.$store.state.inputData.f;
            this.gValue = this.$store.state.inputData.g;
            this.hValue = this.$store.state.inputData.h;
            this.wValue = this.$store.state.inputData.w;
            this.sValue = this.$store.state.inputData.s;
        },
        getPermalink() {
            let link = '#';
            let form = document.getElementById('input');
            let inputs = form.getElementsByTagName('input');
            for (let i in inputs) {
                let input = inputs[i];
                if (input.id) {
                    link += input.id + '=' + input.value + '&';
                }
            }
            console.log(link);
            // permalink.href = link;
            return link;
        },
        loadFromLink(values) {
            for (let i = 0; i < values.length; i++) {
                let s = values[i].split('=', 2);
                if (s.length == 2) { 
                    switch(s[0]) {
                        case 'd':
                            this.dValue = s[1];
                            break;
                        case 'c':
                            this.cValue = s[1];
                            break;
                        case 'p':
                            this.pValue = s[1];
                            break;
                        case 'q':
                            this.qValue = s[1];
                            break;
                        case 'r':
                            this.rValue = s[1];
                            break;
                        case 'A':
                            this.AValue = s[1];
                            break;
                        case 'B':
                            this.BValue = s[1];
                            break;
                        case 'u':
                            this.uValue = s[1];
                            break;
                        case 'v':
                            this.vValue = s[1];
                            break;
                        case 'R':
                            this.RValue = s[1];
                            break;
                        case 'S':
                            this.SValue = s[1];
                            break;
                        case 'f':
                            this.fValue = s[1];
                            break;
                        case 'g':
                            this.gValue = s[1];
                            break;
                        case 'h':
                            this.hValue = s[1];
                            break;
                        case 's':
                            this.sValue = s[1];
                            break;
                        case 'w':
                            this.wValue = s[1];
                            break;
                    }
                }
                this.inputChange() 
            }
        },
        restoreInputItemDefault(input) {
            console.log(input);
            const defaults = this.getDefaults();
            switch (input)  {
                case 'd':
                            this.dValue = defaults.d;
                            break;
                        case 'c':
                            this.cValue = defaults.c;
                            break;
                        case 'p':
                            this.pValue = defaults.p;
                            break;
                        case 'q':
                            this.qValue = defaults.q;
                            break;
                        case 'r':
                            this.rValue = defaults.r;
                            break;
                        case 'A':
                            this.AValue  = defaults.A;
                            break;
                        case 'B':
                            this.BValue = defaults.B;
                            break;
                        case 'u':
                            this.uValue = defaults.u;
                            break;
                        case 'v':
                            this.vValue = defaults.v;
                            break;
                        case 'R':
                            this.RValue = defaults.R;
                            break;
                        case 'S':
                            this.SValue = defaults.S;
                            break;
                        case 'f':
                            this.fValue = defaults.f;
                            break;
                        case 'g':
                            this.gValue = defaults.g;
                            break;
                        case 'h':
                            this.hValue = defaults.h;
                            break;
                        case 's':
                            this.sValue = defaults.s;
                            break;
                        case 'w':
                            this.wValue = defaults.w;
                            break;
            }
            if (input === 's') {
                this.changeSpeed();
            } else {
                this.inputChange();
            }
        }
    },
    created() {
        console.log('input pane created');
        this.$emit('input-ready');
    }

}
</script>

<style>
    table {
	border-spacing: 0;
}

table.input td {
	vertical-align: baseline;
}

input.error {
	background-color: #fdd;
}

td.desc {
	font-size: 50%;
}

td.var {
    font-size: 80%;
}

td.input input {
	width: 5em;
	text-align: right;
	font-family: monospace;
}

td.input-item-restore {
    margin-right: 5px;
}
</style>