<template>
    <canvas id="output" class="output main" width="640" height="640">
        This harmonograph simulator uses the HTML5 canvas element. You need a recent browser to view it, for example, <a href="http://www.mozilla.com/firefox/">Mozilla Firefox</a>, <a href="http://www.google.com/chrome">Google Chrome</a> or <a href="http://www.opera.com/">Opera</a>.
    </canvas>
</template>

<script>
export default {
    name: 'canvas-renderer',
    data() {
        return {
            canvas: null,
            context: null,
            drawCircle: true         
        }
    },
    methods: {
        setCanvasAndContext(canvas, context, drawCircle) {
            this.canvas = canvas;
            this.context = context;
            this.drawCircle = drawCircle;
        },
        clear(w, r) {
            let context = this.context;
            let width = this.canvas.width;
            let height = this.canvas.height;

            context.setTransform(1, 0, 0, 1, 0, 0);
            context.clearRect(0, 0, width, height);
            context.strokeStyle = '#000';
            context.lineWidth = w;
            context.lineCap = 'round';
            context.lineJoin = 'round';
	
            if (r) {
                let scale = Math.min(width * 0.9 / 2.0 / r, height * 0.9 / 2.0 / r);
                context.setTransform(scale, 0, 0, scale, width / 2.0, height / 2.0);
                
                if (this.drawCircle) {
                    context.save();
                    context.beginPath();
                    context.arc(0, 0, r, 0, 2.0 * Math.PI, false);
                    context.stroke();
                    context.restore();
                }
            }
        },
        draw(xs, ys) {
            const context = this.context;
            context.beginPath();
            context.moveTo(xs[0], ys[0]);
            let n = xs.length;
            for (let i = 1; i < n; i++) {
                context.lineTo(xs[i], ys[i]);
            }
            context.stroke();
        }
    }

}
</script>

<style>

</style>